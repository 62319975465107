@import "../variables";

.main-footer{
  background-color: $color-white;
  bottom: 0;
  height: 35px;
  left: 0;
  position: fixed;
  transition: bottom .5s;
  width: 100%;
  z-index: 101;

  .inverted &{
    background-color: $color-black;
  }

  @media only screen and ("min-width: 1400px") {
    height: 70px;
  }
}

.main-footer--hide{
  bottom: -70px;
}

.main-footer__inner{
  border-top: 1px solid $color-black;
  overflow: hidden;

  .inverted &{
    border-color: $color-white;
  }
}

.main-footer__past{
  margin-top: -21px;

  @media only screen and ("min-width: 768px") {
    margin-top: -60px;
  }
}
