@import "../variables";

.main-logo{
  border-bottom: 1px solid $color-black;
  display: block;
  height: 35px;
  overflow: hidden;

  .inverted &{
    border-color: $color-white;
  }

  @media only screen and ("min-width: 1400px") {
    height: 70px;
  }
}

.main-logo__item{
  margin-top: 3px;

  .inverted &{
    fill: $color-white;
  }

  @media only screen and ("max-width: 767px") {
    width: 100%;
    height: auto;
  }
}

.main-header{
  background-color: $color-white;
  height: 70px;
  left: 0;
  position: fixed;
  top: 0;
  transition: top .5s;
  width: 100%;
  z-index: 102;

  .inverted &{
    background-color: $color-black;
  }
}

.main-header--hide{
  top: -70px;
}

.main-header__inner{
  position: relative;
}

.main-header__post{
  @media only screen and ("max-width: 767px") {
    position: relative;
  }
}
