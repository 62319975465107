@import "../variables";

body {
  background-color: $color-white;
  color: $color-black;
  font-family: $ff-ef;

  @media only screen and ("max-width: 1399px") {
    font-size: 90%;
  }

  &.inverted{
    background-color: $color-black;
  }
}

strong{
  font-weight: 700;
}

.is-responsive-image{
  display: block;
  height: auto;
  width: 100%;
}

.has-overlay{
  display: block;
  position: relative;

  &:before{
    background-color: $color-black;
    content: '';
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    transition: opacity .4s;
    width: 100%;
  }

  &:hover,
  &:focus{
    &:before{
      opacity: .5;
    }
  }

  .inverted &{
    &:before{
      background-color: $color-white;
    }
  }
}

.main-content{
  padding-bottom: 35px;
  padding-top: 70px;

  .inverted &{
    background-color: $color-black;
    color: $color-white;
  }

  @media only screen and ("min-width: 1400px") {
    padding-bottom: 70px;
  }
}

.box-content{
  box-sizing: border-box;

  @media only screen and ("min-width: 1400px") {
    padding-right: 2%;
    padding-left: 2%;
  }
}

.box-content__inner{
  box-sizing: border-box;
  padding-right: 2%;
  padding-left: 2%;
}

.main-title{
  padding-bottom: 21px;
  padding-top: 21px;
  text-transform: uppercase;
}

.main-title--extended{
  align-items: center;
  background-color: $color-white;
  border-bottom: 1px solid $color-black;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  left: 2%;
  position: fixed;
  top: 70px;
  transition: top .5s;
  width: 96%;
  z-index: 101;

  .inverted &{
    background-color: $color-black;
    border-color: $color-white;
  }

  @media only screen and ("max-width: 1399px") {
    .main-title__text{
      padding: 0;
    }
  }
}

.main-title--hide{
  top: -78px;

  @media only screen and ("min-width: 1400px") {
    top: -115px;
  }
}

.main-title__text{
  font-family: $ff-me;
  font-size: 3em;
  line-height: 1;

  @media only screen and ("max-width: 1399px") {
    font-size: 2.4em;
    padding-right: 2%;
    padding-left: 2%;
  }
}

.main-title__text--resized{
  font-size: 2.6em;

  @media only screen and ("max-width: 1399px") {
    font-size: 2em;
  }
}
