/* html5doctor.com Reset v1.6.1 - http://cssreset.com */
html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

body {
  line-height: 1;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

nav ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

input, select {
  vertical-align: middle;
}

body {
  background-color: #fff;
  color: #000;
  font-family: "EF", Arial, Helvetica, sans-serif;
}
@media only screen and (max-width: 1399px) {
  body {
    font-size: 90%;
  }
}
body.inverted {
  background-color: #000;
}

strong {
  font-weight: 700;
}

.is-responsive-image {
  display: block;
  height: auto;
  width: 100%;
}

.has-overlay {
  display: block;
  position: relative;
}
.has-overlay:before {
  background-color: #000;
  content: "";
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  transition: opacity 0.4s;
  width: 100%;
}
.has-overlay:hover:before, .has-overlay:focus:before {
  opacity: 0.5;
}
.inverted .has-overlay:before {
  background-color: #fff;
}

.main-content {
  padding-bottom: 35px;
  padding-top: 70px;
}
.inverted .main-content {
  background-color: #000;
  color: #fff;
}
@media only screen and (min-width: 1400px) {
  .main-content {
    padding-bottom: 70px;
  }
}

.box-content {
  box-sizing: border-box;
}
@media only screen and (min-width: 1400px) {
  .box-content {
    padding-right: 2%;
    padding-left: 2%;
  }
}

.box-content__inner {
  box-sizing: border-box;
  padding-right: 2%;
  padding-left: 2%;
}

.main-title {
  padding-bottom: 21px;
  padding-top: 21px;
  text-transform: uppercase;
}

.main-title--extended {
  align-items: center;
  background-color: #fff;
  border-bottom: 1px solid #000;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  left: 2%;
  position: fixed;
  top: 70px;
  transition: top 0.5s;
  width: 96%;
  z-index: 101;
}
.inverted .main-title--extended {
  background-color: #000;
  border-color: #fff;
}
@media only screen and (max-width: 1399px) {
  .main-title--extended .main-title__text {
    padding: 0;
  }
}

.main-title--hide {
  top: -78px;
}
@media only screen and (min-width: 1400px) {
  .main-title--hide {
    top: -115px;
  }
}

.main-title__text {
  font-family: "ME", Arial, Helvetica, sans-serif;
  font-size: 3em;
  line-height: 1;
}
@media only screen and (max-width: 1399px) {
  .main-title__text {
    font-size: 2.4em;
    padding-right: 2%;
    padding-left: 2%;
  }
}

.main-title__text--resized {
  font-size: 2.6em;
}
@media only screen and (max-width: 1399px) {
  .main-title__text--resized {
    font-size: 2em;
  }
}

.main-logo {
  border-bottom: 1px solid #000;
  display: block;
  height: 35px;
  overflow: hidden;
}
.inverted .main-logo {
  border-color: #fff;
}
@media only screen and (min-width: 1400px) {
  .main-logo {
    height: 70px;
  }
}

.main-logo__item {
  margin-top: 3px;
}
.inverted .main-logo__item {
  fill: #fff;
}
@media only screen and (max-width: 767px) {
  .main-logo__item {
    width: 100%;
    height: auto;
  }
}

.main-header {
  background-color: #fff;
  height: 70px;
  left: 0;
  position: fixed;
  top: 0;
  transition: top 0.5s;
  width: 100%;
  z-index: 102;
}
.inverted .main-header {
  background-color: #000;
}

.main-header--hide {
  top: -70px;
}

.main-header__inner {
  position: relative;
}

@media only screen and (max-width: 767px) {
  .main-header__post {
    position: relative;
  }
}

@media only screen and (max-width: 1399px) {
  .main-navigation {
    border-bottom: 1px solid #000;
    padding-right: 2%;
    padding-left: 2%;
  }
  .inverted .main-navigation {
    border-color: #fff;
  }
}
@media only screen and (min-width: 1400px) {
  .main-navigation {
    position: absolute;
    right: 2%;
    top: 21px;
  }
}

.main-navigation__items {
  display: flex;
}
@media only screen and (max-width: 1399px) {
  .main-navigation__items {
    align-items: center;
    height: 33px;
    justify-content: space-between;
  }
}

.main-navigation__item {
  font-family: "ME", Arial, Helvetica, sans-serif;
  font-size: 1.2em;
  text-transform: uppercase;
}
.inverted .main-navigation__item {
  color: #fff;
}
@media only screen and (min-width: 1400px) {
  .main-navigation__item {
    font-size: 1.5em;
    padding-left: 39px;
  }
}

.main-navigation__link {
  color: inherit;
  letter-spacing: 1px;
  text-decoration: none;
  text-shadow: none;
  transition: color 0.2s, text-shadow 0.2s;
}
.main-navigation__link:hover, .main-navigation__link:focus, .main-navigation__link.main-navigation__link--selected {
  color: #fff;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}
.inverted .main-navigation__link:hover, .inverted .main-navigation__link:focus, .inverted .main-navigation__link.main-navigation__link--selected {
  color: #000;
  text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;
}

.main-footer {
  background-color: #fff;
  bottom: 0;
  height: 35px;
  left: 0;
  position: fixed;
  transition: bottom 0.5s;
  width: 100%;
  z-index: 101;
}
.inverted .main-footer {
  background-color: #000;
}
@media only screen and (min-width: 1400px) {
  .main-footer {
    height: 70px;
  }
}

.main-footer--hide {
  bottom: -70px;
}

.main-footer__inner {
  border-top: 1px solid #000;
  overflow: hidden;
}
.inverted .main-footer__inner {
  border-color: #fff;
}

.main-footer__past {
  margin-top: -21px;
}
@media only screen and (min-width: 768px) {
  .main-footer__past {
    margin-top: -60px;
  }
}

.loader {
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  width: 100%;
  z-index: 201;
}

.loader__piece {
  background-color: #000;
  height: 16.6666666667vh;
  width: 0;
}
.inverted .loader__piece {
  background-color: #fff;
}