@import "../variables";

.loader{
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  width: 100%;
  z-index: 201;
}

.loader__piece{
  background-color: $color-black;
  height: (100vh / 6);
  width: 0;

  .inverted &{
    background-color: $color-white;
  }
}
