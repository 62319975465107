@import "../variables";

.main-navigation{
  @media only screen and ("max-width: 1399px") {
    border-bottom: 1px solid $color-black;
    padding-right: 2%;
    padding-left: 2%;

    .inverted &{
      border-color: $color-white;
    }
  }

  @media only screen and ("min-width: 1400px") {
    position: absolute;
    right: 2%;
    top: 21px;
  }
}

.main-navigation__items{
  display: flex;

  @media only screen and ("max-width: 1399px") {
    align-items: center;
    height: 33px;
    justify-content: space-between;
  }
}

.main-navigation__item{
  font-family: $ff-me;
  font-size: 1.2em;
  text-transform: uppercase;

  .inverted &{
    color: $color-white;
  }

  @media only screen and ("min-width: 1400px") {
    font-size: 1.5em;
    padding-left: 39px;
  }
}

.main-navigation__link{
  color: inherit;
  letter-spacing: 1px;
  text-decoration: none;
  text-shadow: none;
  transition: color .2s, text-shadow .2s;

  &:hover,
  &:focus,
  &.main-navigation__link--selected{
    color: $color-white;
    text-shadow: -1px -1px 0 $color-black, 1px -1px 0 $color-black, -1px 1px 0 $color-black, 1px 1px 0 $color-black;
  }

  .inverted &{
    &:hover,
    &:focus,
    &.main-navigation__link--selected{
      color: $color-black;
      text-shadow: -1px -1px 0 $color-white, 1px -1px 0 $color-white, -1px 1px 0 $color-white, 1px 1px 0 $color-white;
    }
  }
}
